import { default as $ } from 'jquery';

$(document).on('DOMContentLoaded', function() {
    if ($(window).width() < 768) {
        $('#header').addClass('-dark');  
        
    } else {
        var header = $("#header");
        $(window).scroll(function() {
            var scroll = $(window).scrollTop();
    
            if (scroll >= 100) {
                header.addClass("-dark");
            } else {
                header.removeClass("-dark");
            }
        });    
    }
    
    $('#hamburgerButton').on('click', function(){
        $('#body').toggleClass('-no-scroll');
        $('.header-hamburger-bars').toggleClass('-active');
    });
    
    $('.menu-item-has-children').on('click', function(){
        $(this).toggleClass('-show');
    });
});
