import { default as $ } from 'jquery';
import '@accessible360/accessible-slick';

$(document).on('DOMContentLoaded', function() {
    $('.m-featured-list.-isCarousel').slick({
        dots: false,
        arrows: false,
        swipeToSlide: true,
        centerMode: false,
        slidesToShow: 3.5,
        slidesToScroll: 1,
        infinite: false,
        controls: true,
        autoplay: false,
        autoplaySpeed: 4000,
        pauseOnHover: true,
        useAutoplayToggleButton: false,
        rows: 0,
        responsive: [
            {
                breakpoint: 1850,
                settings: {
                    slidesToShow: 3.3,
                }
            },
            {
                breakpoint: 1700,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 2.8,
                }
            },
            {
                breakpoint: 1300,
                settings: {
                    slidesToShow: 2.6,
                }
            },
            {
                breakpoint: 1100,
                settings: {
                    slidesToShow: 2.4,
                }
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 700,
                settings: {
                    slidesToShow: 1.7,
                }
            },
            {
                breakpoint: 560,
                settings: {
                    slidesToShow: 1.5,
                }
            },
            {
                breakpoint: 460,
                settings: {
                    slidesToShow: 1.2,
                }
            },
            {
                breakpoint: 360,
                settings: {
                    slidesToShow: 1,
                }
            },
        ]
    });
});