// This file contains all parachute imports, e.g:
import './accordion/accordion';
import './animation/animation';
import './mmenu/mmenu';
import './header/header';
import './splitting/splitting';
import './carousel/carousel';
import './carousel/featured';
import './carousel/cocktails';
import './carousel/testimonials';
import './carousel/horizontal';
import './carousel/cocktail-menu';

// Publicly accessible methods for this build, useful for callbacks in script tags etc.
// Try and keep most things inside this webpack bundle though
import { publicExports } from './public-exports';
window._Parachute = publicExports || {};
