import { default as $ } from 'jquery';

$(window).on("load",function() {
   $(".m-accordion-content").slideUp();
   $(".m-accordion-chevron").removeClass("-upsideDown");
   
});
$(document).ready(function () {
   $(".m-accordion-heading").on("click", function(){
      if ($(this).closest(".m-accordion-section").hasClass("-noShow")) {
         $(this).closest(".m-accordion-section").removeClass("-noShow");
         $(this).closest(".m-accordion-section").find(".m-accordion-content").slideToggle();
         $(this).closest(".m-accordion-section").find(".m-accordion-chevron").toggleClass("-upsideDown");
      } else {
         $(this).closest(".m-accordion-section").addClass("-noShow");
         $(this).closest(".m-accordion-section").find(".m-accordion-content").slideToggle();
         $(this).closest(".m-accordion-section").find(".m-accordion-chevron").toggleClass("-upsideDown");
      };
   });

   $('.m-faqs-checkboxes input[type="checkbox"]').on('change', function () {
      let checkedIds = $('.m-faqs-checkboxes input[type="checkbox"]:checked').map(function () {
         return $(this).attr('id');
      }).get();
       
      $('.m-faqs-section').addClass('-hide');
       
      if (checkedIds.length === 0) {
         $('.m-faqs-section').removeClass('-hide');
      } else {
         checkedIds.forEach(function (id) {
            $('.category-' + id).removeClass('-hide');
         });
      };
   });
});
