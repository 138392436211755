import { default as $ } from 'jquery';
import '@accessible360/accessible-slick';

$(document).on('DOMContentLoaded', function() {
    $('.m-testimonials-list.-isCarousel').slick({
        dots: false,
        arrows: false,
        swipeToSlide: true,
        centerMode: false,
        slidesToShow: 2.8,
        slidesToScroll: 1,
        infinite: false,
        controls: true,
        autoplay: false,
        autoplaySpeed: 4000,
        pauseOnHover: true,
        useAutoplayToggleButton: false,
        rows: 0,
        responsive: [
            {
                breakpoint: 1900,
                settings: {
                    slidesToShow: 2.6,
                }
            },
            {
                breakpoint: 1700,
                settings: {
                    slidesToShow: 2.4,
                }
            },
            {
                breakpoint: 1600,
                settings: {
                    slidesToShow: 2.2,
                }
            },
            {
                breakpoint: 1500,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 1.7,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1.4,
                }
            },
            {
                breakpoint: 700,
                settings: {
                    slidesToShow: 1.2,
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                }
            },
        ]
    });
});