import { default as $ } from 'jquery';
import '@accessible360/accessible-slick';

$(document).on('DOMContentLoaded', function() {
    $('.m-carousel-list.-isCarousel').slick({
        dots: false,
        arrows: false,
        swipeToSlide: true,
        slidesToShow: 4,
        centerMode: false,
        variableWidth: true,
        slidesToScroll: 1,
        infinite: true,
        controls: true,
        autoplay: false,
        autoplaySpeed: 4000,
        pauseOnHover: true,
        useAutoplayToggleButton: false,
        rows: 0,
        responsive: [
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 1,
                    centerMode: true,
                    variableWidth: true,
                }
            }
        ]
    });
});